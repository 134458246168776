import React, { Component } from 'react';
import { LoadingSmall } from './shared/loadings';
import { ErrorAlert } from './shared/alerts';
import authService from './api-authorization/AuthorizeService'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Trans } from 'react-i18next';
import FeatherIcon from 'feather-icons-react';
import Cookies from 'universal-cookie';

export class DatabaseSwitcher extends Component {
    static displayName = DatabaseSwitcher.name;

    constructor(props) {
        super(props);
        this.state = { forecasts: [], loading: true, error: false, errorMessage: "" };
    }

    componentDidMount() {
        this.populateData();
    }

    static renderForecasts(forecasts) {        
        const cookies = new Cookies();
        var dbName = cookies.get("connectionName");

        if (typeof dbName === "undefined") {
            dbName = "Connection"
        }

        return (
            <>
                <UncontrolledDropdown nav inNavbar className="text-dark">
                    <DropdownToggle nav caret>
                        <span>
                            <FeatherIcon icon="database" size="16" /> <Trans>{dbName}</Trans>
                        </span>
                    </DropdownToggle>
                    <DropdownMenu className="navbar-dropdown-caret dropdown-menu">
                        {forecasts.data !== undefined && forecasts.data.map(forecast =>
                            <DropdownItem key={forecast.id} onClick={() => changeDB(forecast.id, forecast.connection_name)}>
                            <span>
                                <Trans>{forecast.connection_name}</Trans>
                            </span>
                        </DropdownItem>
                        )}
                    </DropdownMenu>
                </UncontrolledDropdown>                   
            </>
        );
    }

    render() {
        let contents = this.state.loading
            ? <LoadingSmall />
            : DatabaseSwitcher.renderForecasts(this.state.forecasts);

        return (
            <>
                {this.state.error && <ErrorAlert message={this.state.errorMessage} />}
                {contents}
            </>
        );
    }

    async populateData() {
        const token = await authService.getAccessToken();
        const response = await fetch('api/wms/DatabaseInfo', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });

        if (response.status === 200) {
            const data = await response.json();
            this.setState({ forecasts: data, loading: false });
        }
        else {
            this.setState({ loading: false, error: true, errorMessage: response.status + " " + response.statusText });
        }
    }
}

function changeDB(db, db_name) {
    const cookies = new Cookies();
    cookies.set("connectionDb", db, { path: "/" });
    cookies.set("connectionName", db_name, { path: "/" });
    window.location.reload(false)
}