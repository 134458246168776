import React, { Component } from 'react';
import { useLocation } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { Trans } from 'react-i18next';
import i18n from "i18next";
import Cookies from 'universal-cookie';
import { Loading } from '../shared/loadings';
import { ErrorAlert, ErrorAlertLarge } from '../shared/alerts';
import authService from '../api-authorization/AuthorizeService'
import { Button, ButtonGroup, ButtonToolbar, Dropdown, Pagination, Table, Row, Col, Modal, Form, Tab, Tabs, Spinner } from 'react-bootstrap';
import Moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortAmountAsc, faSortAmountDesc, faAlignLeft, faGear, faSearch, faRefresh, faEllipsisV, faEyeLowVision, faEye, faMoneyCheck, faTextHeight } from '@fortawesome/free-solid-svg-icons'
import Badge from '../base/Badge.tsx';
import { ReactSelect, ReactMultiSelect, ReactAsyncSelect } from '../base/ReactSelect.tsx';


export function FormList() {
    const { search } = useLocation();
    const match = search.match(/formid=(.*)/);
    const formid = match?.[1];

    document.title = i18n.t(formid) + ' - PowerWMS';

    return (
            <>
                <FormListData formid={formid} />
            </>
    );
}

function FormListData({ formid }) {
    return (
        <>
            <TableData formid={formid} />
        </>
    );
}

class TableData extends Component {
    //static displayName = FormList.name;

    constructor(props) {
        super(props);
        this.state = {
            forecasts: [], loading: true, error: false, errorMessage: "", update: false, Page: 1, Search: {} , OrderBy: {} };
    }

    componentDidMount() {
        this.populateData();
    }

    //����������
    componentDidUpdate(prevProps) {
        if (this.state.update) {
            //alert(this.state.action)
            this.populateData();
        }
        else
            if (prevProps.formid !== this.props.formid) {
                this.setState({ loading: true, update: true, Page: 1, Search: {}, OrderBy: {}, error: false })
            }
    }

    handleUpdateTable = (action, id, id2) => {
        if (action === "NEXT") {
            this.setState({ loading: true, update: true, Page: this.state.Page + 1 })
        }
        else
            if (action === "PREV") {
                this.setState({ loading: true, update: true, Page: this.state.Page - 1 })
            }
            else
                if (action === "PAGE") {
                    this.setState({ loading: true, update: true, Page: id })
                }
                else
                    if (action === "ORDERBY") {
                        this.setState({ loading: true, update: true, Page: 1, OrderBy: { COLUMN: id, DIRECTION: id2 } })
                    }
                    else
                        if (action === "REFRESH") {
                            this.setState({ loading: true, update: true })
                        }
                        else
                            if (action === "SEARCH") {
                                this.setState({ loading: true, update: true, Page: 1, Search: id })
                            }
    }

    static renderForecasts(forecasts, formid, id, topPadding, handleUpdateTable) {
        var JsonData = JSON.parse(forecasts.data)[0];
        var JsonParam = JSON.parse(forecasts.param);

        return (
            <>
                <TableToolbar formid={formid} id={id} topPadding={topPadding} JsonData={JsonData} JsonParam={JsonParam} handleUpdateTable={handleUpdateTable} /> 
                <TableGrid formid={formid} id={id} JsonData={JsonData} JsonParam={JsonParam} handleUpdateTable={handleUpdateTable} />
            </>
        );
    }

    render() {
        let contents = <></>;

        if (!this.state.error) {
            contents = this.state.loading
                ? <Loading />
                : TableData.renderForecasts(this.state.forecasts, this.props.formid, this.props.id, this.props.topPadding, this.handleUpdateTable);
        }

        return (
            <>
                {this.state.error
                    ? <ErrorAlertLarge message={this.state.errorMessage} />
                    : contents}
            </>
        );
    }

    async populateData() {
        //const { i18n } = useTranslation();
        const cookies = new Cookies();
        var dbName = cookies.get("connectionDb");
        var language = cookies.get("language");

        const token = await authService.getAccessToken();
        const response = await fetch('api/forms/TableInfo', {
            timeout: 60000,
            method: "POST",
            headers: !token
                ? {}
                : {
                    'Authorization': `Bearer ${token}`
                },
            body: JSON.stringify({ tableName: this.props.formid, id: this.props.id, language: language, connection: dbName, page: this.state.Page, search: this.state.Search, order: this.state.OrderBy })
        });

        if (response.status === 200) {
            const data = await response.json();
            this.setState({ forecasts: data, loading: false, update: false });
        }
        else {
            try {
                const data = await response.json();
                this.setState({ loading: false, error: true, update: false, errorMessage: data.error });
            } catch {
                this.setState({ loading: false, error: true, update: false, errorMessage: response.status + " " + response.statusText });
            }
        }
    }
}

function TableToolbar({ formid, id, topPadding, JsonData, JsonParam, handleUpdateTable }) {
    var Page = JsonParam.page;
    var PageCount = JsonParam.pageCount;
    var PrevDisable = JsonParam.page === 1;
    var NextDisable = JsonParam.page === PageCount;    

    const PaginationItems = [];
    for (let i = 1; i <= PageCount; i++) {
        PaginationItems.push(<Dropdown.Item key={i} onClick={() => handleUpdateTable("PAGE",i)} >{i}</Dropdown.Item>);
    }

    return (
        <>
            <div className={topPadding === "YES" ? "row py-3" : "row"}>
                <div className="col-md-10">
                    <div className="btn-toolbar" role="toolbar">
                        <ButtonGroup>
                            <Button variant="outline-dark" className="me-1" size="sm" disabled><Trans>{formid}</Trans></Button>
                        </ButtonGroup>
                        {
                            JsonData.ACTIONS !== undefined &&
                            <ButtonGroup>
                                <Dropdown>
                                    <Dropdown.Toggle variant="info" className="me-1" size="sm">
                                        <Trans>Action</Trans>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                            {
                                                JsonData.ACTIONS.map(action =>
                                                    <ActionItem key={action.action} action={action} handleUpdateTable={handleUpdateTable} />
                                                )
                                            }
                                    </Dropdown.Menu>
                                </Dropdown>
                            </ButtonGroup>
                        }                        
                        <ButtonGroup>
                            <TableSettings formid={formid} JsonData={JsonData} JsonParam={JsonParam} handleUpdateTable={handleUpdateTable} />
                            <TableSearch JsonData={JsonData} JsonParam={JsonParam} handleUpdateTable={handleUpdateTable} />
                            <Button variant="outline-dark" size="sm" onClick={() => handleUpdateTable("REFRESH")}>
                                <FontAwesomeIcon icon={faRefresh} />
                            </Button>
                        </ButtonGroup>
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="btn-toolbar float-end" role="toolbar">
                        <Pagination className='mb-0 justify-content-center'>
                            {
                                PrevDisable ?
                                    <Pagination.Prev disabled>
                                        <FeatherIcon icon='chevron-left' size="16" />
                                    </Pagination.Prev>
                                    :
                                    <Pagination.Prev onClick={() => handleUpdateTable("PREV")}>
                                        <FeatherIcon icon='chevron-left' size="16" />
                                    </Pagination.Prev>
                            }                            
                            <Dropdown className="page-item" size="sm">
                                <Dropdown.Toggle variant="" className="btn btn-sm dropdown-toggle dropdown-caret-none transition-none phoenix-primary">
                                    {Page}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="py-2" style={{ maxHeight: "50vh" }}>
                                    <div className="border-0 scrollbar" style={{ maxHeight: "48vh" }} >
                                        <div className="px-3 img-dropdown scrollbar">
                                            <div className="gx-4 row">
                                                {PaginationItems}
                                            </div>
                                        </div>
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                            {
                                NextDisable ?
                                    <Pagination.Next disabled>
                                        <FeatherIcon icon="chevron-right" size="16" />
                                    </Pagination.Next>
                                    :
                                    <Pagination.Next onClick={() => handleUpdateTable("NEXT")}>
                                        <FeatherIcon icon="chevron-right" size="16"/>
                                    </Pagination.Next>                                    
                            }                         
                        </Pagination>
                    </div>
                </div>
            </div>
        </>
    );
}

function TableGrid({ formid, id, JsonData, JsonParam, handleUpdateTable }) {
    var orderBy = JsonParam.order;

    return (
        <>
            <Table bordered size="sm" striped hover>
                <thead>
                    <tr key="Header">
                        {JsonData.SETTINGS.FIELDS !== undefined && JsonData.SETTINGS.FIELDS.map(field =>
                            field.visible &&
                            <th key={field.id}>
                                <div className="row">
                                    {
                                          field.orderable
                                                ?
                                                <>
                                                    <div className="col-md-9 py-2 text-center">
                                                        <Trans>{field.id}</Trans>
                                                    </div>
                                                    <div className="col-md-3 text-end">
                                                        {
                                                            orderBy.COLUMN === field.id && orderBy.DIRECTION === "ASC" &&
                                                            <Button variant='outline-dark' className="px-2" size="sm" onClick={() => handleUpdateTable("ORDERBY", field.id, "DESC")} >
                                                                <FontAwesomeIcon icon={faSortAmountDesc} />
                                                            </Button>
                                                        }
                                                        {
                                                            orderBy.COLUMN === field.id && orderBy.DIRECTION === "DESC" &&
                                                            <Button variant='outline-dark' className="px-2" size="sm" onClick={() => handleUpdateTable("ORDERBY", field.id, "ASC")} >
                                                                <FontAwesomeIcon icon={faSortAmountAsc} />
                                                            </Button>
                                                        }
                                                        {
                                                            orderBy.COLUMN !== field.id &&
                                                            <Button variant='outline-dark' className="px-2" size="sm" onClick={() => handleUpdateTable("ORDERBY", field.id, "ASC")} >
                                                                <FontAwesomeIcon icon={faAlignLeft} />
                                                            </Button>
                                                        }

                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div className="py-2 text-center">
                                                        <Trans>{field.id}</Trans>
                                                    </div>
                                                </>
                                    }                                    
                                </div>
                            </th>

                        )}
                        <th>                            
                        </th>
                    </tr>                    
                </thead>
                <tbody>
                    {JsonData.DATA !== undefined && JsonData.DATA.map(row =>
                        <tr key={row.id}>
                            {JsonData.SETTINGS.FIELDS !== undefined && JsonData.SETTINGS.FIELDS.map(field =>
                                field.visible &&
                                <td key={row.id + field.id} className={field.type === "NUMBER" ? "text-end px-2" : "px-2"}>
                                    <TableField formid={formid} id={id} Field={field} Value={row[field.id]} handleUpdateTable={handleUpdateTable} />
                                </td>
                            )}
                            <td className="text-center px-0" style={{ width: "20px" }}>    
                                {
                                    row["ACTIONS"] !== undefined &&
                                    <Dropdown className="px-0">
                                        <Dropdown.Toggle variant="btn btn-sm dropdown-toggle dropdown-caret-none transition-none outline-dark" size="sm" className="pt-0 pb-0 px-0" style={{ width: "20px" }}>
                                            <FontAwesomeIcon icon={faEllipsisV} />
                                        </Dropdown.Toggle>
                                            <Dropdown.Menu className="py-0">
                                                {
                                                    row["ACTIONS"].map(action =>
                                                        <ActionItem key={action.action} action={action} handleUpdateTable={handleUpdateTable} />
                                                    )
                                                }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                }
                            </td>
                        </tr>
                    )}                    
                </tbody>
            </Table>
        </>
    );
}

function TableField({ formid, id, Field, Value, handleUpdateTable }) {
    let contents = <></>
    if (Field.href !== undefined) {
        if (Field.href === "FORM") {
            contents = <TableFieldForm formid={formid} id={id} Field={Field} Value={Value} handleUpdateTable={handleUpdateTable} />
        }
        else {
            const href = Field.href.replace("(ID)", Value);
            contents = <a href={href} target="_blank" rel="noreferrer">{Value}</a>
        }        
    }
    else
    if (Field.badge) {
        if (Field.type === "STRING") {
            contents = <div className="row px-3"><Badge bg="info">{Value}</Badge></div>
        }
        if (Field.type === "DATE") {
            contents = <div className="row px-3"><Badge pill bg="info">{Moment(Value).format('DD.MM.YYYY')}</Badge></div>
        }
        if (Field.type === "DATETIME") {
            contents = <div className="row px-3"><Badge pill bg="info">{Moment(Value).format('DD.MM.YYYY  HH:mm')}</Badge></div>
        }
        if (Field.type === "NUMBER") {
            contents = <div className="row px-3"><Badge pill bg="info">{Value}</Badge></div>
        }
    }
    else {
        if (Field.type === "STRING") {
            contents = Value
        }
        if (Field.type === "DATE") {
            contents = Moment(Value).format('DD.MM.YYYY')
        }
        if (Field.type === "DATETIME") {
            contents = Moment(Value).format('DD.MM.YYYY  HH:mm')
        }
        if (Field.type === "NUMBER") {
            contents = Value
        }
    }

    return (
        <>
            {contents}
        </>
    );
}

class TableFieldForm extends Component {
    //static displayName = TableFieldForm.name;

    constructor(props) {
        super(props);
        this.state = { modalShow: false, error: false, errorMessage: "" };
    }

    closeModal = () => {
        this.setState({ modalShow: false });
    };

    render() {
        const currentId = this.props.id + this.props.Value;

        return (
            <>
                <Button variant='link' className="px-0 py-0" onClick={() => this.setState({ modalShow: true, error: false })}>{this.props.Value}</Button>

                <Modal
                    show={this.state.modalShow}
                    onHide={() => this.setState({ modalShow: false })}
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter"><Trans>{this.props.formid}</Trans>&nbsp;{this.props.Value}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormData formid={this.props.formid} id={currentId} handleUpdateTable={this.props.handleUpdateTable} closeModal={this.closeModal} />
                    </Modal.Body>                    
                </Modal>
            </>
        );
    }
}
class TableSettings extends Component {
    //static displayName = TableSettings.name;

    constructor(props) {
        super(props);
        if (this.props.JsonData !== undefined) {
            this.state = { modalShow: false, settings: this.props.JsonData.SETTINGS, error: false, errorMessage: "" };
        }
        else {
            this.state = { modalShow: false };
        }
        this.state.settings.DEFAULT = false;
    }
 
    setModalShow = (modalShow) => {
        this.setState({ modalShow: modalShow, error: false  });
    }

    setSettingValue = (field, value) => {
        const settings = this.state.settings;

        if (settings[field] !== undefined) {
            settings[field] = value;
            this.setState({ settings: settings });
        }
    }

    setSettingFieldValue = (field, setting, value) => {
        const settings = this.state.settings;

        for (var i in settings.FIELDS) {
            if (settings.FIELDS[i].id === field) {
                settings.FIELDS[i][setting] = value;
                this.setState({ settings: settings });
                break;
            }
        }

    }

    saveSettings = (type) => {
        var settings = {};

        
        if (type === "APPLY") {
            settings = this.state.settings;
        };

        this.populateSettings(settings);
    }

    async populateSettings(settings) {
        //const { i18n } = useTranslation();
        const cookies = new Cookies();
        var dbName = cookies.get("connectionDb");
        var language = cookies.get("language");

        const token = await authService.getAccessToken();
        const response = await fetch('api/forms/TableSettings', {
            method: "POST",
            headers: !token
                ? {}
                : {
                    'Authorization': `Bearer ${token}`
                },
            body: JSON.stringify({ tableName: this.props.formid, language: language, connection: dbName, settings: settings })
        });
        
        if (response.status === 200) {
            this.props.handleUpdateTable("REFRESH", {});
        }
        else {
            try {
                const data = await response.json();
                this.setState({ error: true, errorMessage: data.error })
            } catch {
                this.setState({ error: true, errorMessage: response.status + " " + response.statusText })
            }
        }
    }

    render() {
        return (
            <>
                <Button variant="outline-dark" size="sm" onClick={() => this.setModalShow(true)}>
                    <FontAwesomeIcon icon={faGear} />
                </Button>

                <Modal
                    show={this.state.modalShow}
                    onHide={() => this.setModalShow(false)}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="modal show"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter"><Trans>Settings</Trans></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.settings.FIELDS !== undefined && this.state.settings.FIELDS.map(field =>
                            <div className="row" key={field.id}>
                                <div className="col-md-6 py-2">
                                    <div className="d-grid gap-2">
                                        <Button variant="outline-dark" size="sm" disabled><Trans>{field.id}</Trans></Button>
                                    </div>
                                </div>
                                <div className="col-md-6 text-end py-2">
                                    <ButtonGroup>
                                        {
                                            field.visible
                                                ?
                                                <Button variant="outline-dark" size="sm">
                                                    <FontAwesomeIcon icon={faEye} onClick={() => this.setSettingFieldValue(field.id, "visible", !field.visible)} />
                                                </Button>
                                                :
                                                <Button variant="outline-dark" size="sm">
                                                    <FontAwesomeIcon icon={faEyeLowVision} onClick={() => this.setSettingFieldValue(field.id, "visible", !field.visible)} />
                                                </Button>
                                        }
                                        {
                                            field.badge
                                                ?
                                                <Button variant="outline-dark" size="sm">
                                                    <FontAwesomeIcon icon={faMoneyCheck} onClick={() => this.setSettingFieldValue(field.id, "badge", !field.badge)} />
                                                </Button>
                                                :
                                                <Button variant="outline-dark" size="sm">
                                                    <FontAwesomeIcon icon={faTextHeight} onClick={() => this.setSettingFieldValue(field.id, "badge", !field.badge)} />
                                                </Button>
                                        }
                                        
                                    </ButtonGroup>
                                </div>
                            </div>
                        )}
                        <div className="row">
                            <div className="col-md-6 py-2">
                                <div className="d-grid gap-2">
                                    <Button variant="outline-dark" size="sm" disabled><Trans>Page Size</Trans></Button>
                                </div>
                            </div>
                            <div className="col-md-6 py-2">
                                <Form.Control placeholder="" size="sm" value={this.state.settings.PAGESIZE} onChange={(event) => this.setSettingValue("PAGESIZE", event.target.value)} />
                            </div>
                        </div>
                        {this.state.error && <ErrorAlert message={this.state.errorMessage} />}
                        <div className="row">
                            <div className="col-md-6 py-2">
                                <div className="d-grid gap-2">
                                    <Button variant="outline-dark" size="sm" disabled><Trans>Default</Trans></Button>
                                </div>
                            </div>
                            <div className="col-md-6 text-end py-3">
                                <Form.Check type='switch' onChange={() => this.setSettingValue("DEFAULT", !this.state.settings.DEFAULT)} checked={this.state.settings.DEFAULT} />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => this.saveSettings("CLEAR")}><Trans>Clear</Trans></Button>
                        <Button onClick={() => this.saveSettings("APPLY")}><Trans>Apply</Trans></Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}

class TableSearch extends Component {
    //static displayName = TableSearch.name;    

    constructor(props) {
        super(props);
        this.state = { modalShow: false, search: [] };

        if (this.props.JsonParam !== undefined) {
            for (var i in this.props.JsonParam.search) {
                this.setSearch(this.props.JsonParam.search[i].COLUMN, this.props.JsonParam.search[i].VALUE);
            }
        }  
    }

    setModalShow = (modalShow) => {
        this.setState({ modalShow: modalShow });
    }

    setSearch = (field, value) => {
        const search = this.state.search;
        let found = false;

        for (var i in search) {
            if (search[i].COLUMN === field) {
                search[i].VALUE = value;
                found = true;
                break;
            }
        }

        if (!found) {
            const currentField = { COLUMN: field, VALUE: value }
            search.push(currentField);
        }        

        this.setState({ search: search });
    }

    Applylick = () => {
        const searchJSON = JSON.stringify(this.state.search);
        this.props.handleUpdateTable("SEARCH", searchJSON);
    }

    getSearchValue = (column) => {
        for (var i in this.state.search) {
            if (this.state.search[i].COLUMN === column) {
                return this.state.search[i].VALUE;
            }
        }         
        return "";
    }

    render() {
        return (
            <>
                <Button variant="outline-dark" size="sm" onClick={() => this.setModalShow(true)}>
                    <FontAwesomeIcon icon={faSearch} />
                </Button>

                <Modal
                    show={this.state.modalShow}
                    onHide={() => this.setModalShow(false)}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="modal show"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter"><Trans>Search</Trans></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.JsonData.SETTINGS.FIELDS !== undefined && this.props.JsonData.SETTINGS.FIELDS.map(field =>
                            field.filterable &&
                            <div className="row" key={field.id}>
                                <div className="col-md-4 py-2">
                                    <div className="d-grid gap-2">
                                        <Button variant="outline-dark" size="sm" disabled><Trans>{field.id}</Trans></Button>
                                    </div>
                                </div>
                                <div className="col-md-4 py-2">
                                    <div className="d-grid gap-2">
                                        <Form.Control placeholder="LIKE" size="sm" disabled />
                                    </div>
                                </div>
                                <div className="col-md-4 py-2">
                                    <div className="d-grid gap-2">
                                        <Form.Control key={field.id} placeholder="" size="sm" onChange={(event) => this.setSearch(field.id, event.target.value)} value={this.getSearchValue(field.id)} />                                     
                                    </div>
                                </div>
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => this.props.handleUpdateTable("SEARCH", {})}><Trans>Clear</Trans></Button>
                        <Button onClick={this.Applylick}><Trans>Apply</Trans></Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
};

export function FormEdit() {
    const { search } = useLocation();
    const match = search.match(/formid=(.*)&id=(.*)/);
    const formid = match?.[1];
    const id = match?.[2];

    document.title = i18n.t(formid) + ' ' + id + ' - PowerWMS';

    return (
        <>
            <FormEditData formid={formid} id={id} />
        </>
    );
}

function FormEditData({ formid, id }) {
    return (
        <>
            <FormData formid={formid} id={id} />
        </>
    );
}

class FormData extends Component {
    //static displayName = FormData.name;

    constructor(props) {
        super(props);
        this.state = {
            forecasts: [], loading: true, error: false, errorMessage: "", update: false, fields: {}
        };
        this.fields = {};
    }

    componentDidMount() {
        this.populateData();
    }

    //����������
    componentDidUpdate(prevProps) {
        if (this.state.update) {
            //alert(this.state.action)
            this.populateData();
        }
        else
            if (prevProps.formid !== this.props.formid || prevProps.id !== this.props.id) {
                this.setState({ loading: true, update: true, error: false })
            }
    }

    handleUpdateTable = (action, id) => {
        if (action === "REFRESH") {
            this.setState({ loading: true, update: true })
        }                        
    }

    fieldChange = (field, value) => {
        //alert(field + " = " + value);
        this.fields[field] = value;
        //alert(this.fields);
    }

    getFields = () => {
        return this.fields;
    }

    static renderForecasts(forecasts, formid, id, handleUpdateTable, fieldChange, getFields, closeModal, handleUpdateModal) {
        var JsonData = JSON.parse(forecasts.data)[0];
        var JsonParam = JSON.parse(forecasts.param);

        //this.fields = JsonData.DATA[0];
        //this.state.fields = JsonData.DATA[0];

        return (
            <>
                {
                    (JsonData.SETTINGS.Modal === undefined || !JsonData.SETTINGS.Modal) &&
                    <FormToolbar formid={formid} id={id} JsonData={JsonData} JsonParam={JsonParam} handleUpdateTable={handleUpdateTable} getFields={getFields} />
                }                
                {
                    JsonData.SETTINGS.Header !== undefined &&
                    <FormHeader formid={formid} id={id} JsonData={JsonData} JsonParam={JsonParam} handleUpdateTable={handleUpdateTable} fieldChange={fieldChange} getFields={getFields} />
                }
                {
                    JsonData.SETTINGS.Form !== undefined &&
                    <FormModal formid={formid} id={id} JsonData={JsonData} JsonParam={JsonParam} handleUpdateTable={handleUpdateTable} fieldChange={fieldChange} getFields={getFields} />
                }
                {
                    JsonData.SETTINGS.Detail !== undefined &&
                    <TableData formid={JsonData.SETTINGS.Detail.TableName} id={id} topPadding="YES"/>
                }
                {
                    (JsonData.SETTINGS.Modal) &&
                    <FormActionbar formid={formid} id={id} JsonData={JsonData} JsonParam={JsonParam} handleUpdateTable={handleUpdateModal} getFields={getFields} closeModal={closeModal} />
                } 
            </>
        );
    }

    render() {
        let contents = <></>;

        if (!this.state.error) {
            contents = this.state.loading
                ? <Loading />
                : FormData.renderForecasts(this.state.forecasts, this.props.formid, this.props.id, this.handleUpdateTable, this.fieldChange, this.getFields, this.props.closeModal, this.props.handleUpdateTable);
        }

        if (!this.state.error && !this.state.loading) {
            var JsonData = JSON.parse(this.state.forecasts.data)[0];
            this.fields = JsonData.DATA[0];
        }                    

        return (
            <>
                {this.state.error
                    ? <ErrorAlert message={this.state.errorMessage} />
                    : contents}
            </>
        );
    }

    async populateData() {
        //const { i18n } = useTranslation();
        const cookies = new Cookies();
        var dbName = cookies.get("connectionDb");
        var language = cookies.get("language");

        const token = await authService.getAccessToken();
        const response = await fetch('api/forms/FormInfo', {
            method: "POST",
            headers: !token
                ? {}
                : {
                    'Authorization': `Bearer ${token}`
                },
            body: JSON.stringify({ formName: this.props.formid, id: this.props.id,  language: language, connection: dbName })
        });

        if (response.status === 200) {
            const data = await response.json();

            this.setState({ forecasts: data, loading: false, update: false });
        }
        else {
            try {
                const data = await response.json();
                this.setState({ loading: false, error: true, update: false, errorMessage: data.error });
            } catch {
                this.setState({ loading: false, error: true, update: false, errorMessage: response.status + " " + response.statusText });
            }
        }
    }
}

class FormToolbar extends Component {
    //static displayName = FormToolbar.name;

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <div className="row">
                    <div className="col-md-10">
                        <div className="btn-toolbar" role="toolbar">
                            <ButtonGroup>
                                <Button variant="outline-dark" className="me-1" size="sm" disabled><Trans>{this.props.formid}</Trans> {this.props.id}</Button>
                            </ButtonGroup>
                            {
                                this.props.JsonData.ACTIONS !== undefined &&
                                <ButtonGroup>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="info" className="me-1" size="sm">
                                            <Trans>Action</Trans>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                                {
                                                    this.props.JsonData.ACTIONS.map(action =>
                                                        <ActionItem key={action.action} action={action} formid={this.props.formid} handleUpdateTable={this.props.handleUpdateTable} getFields={this.props.getFields}/>
                                                    )
                                                }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </ButtonGroup>
                            }
                            {
                                this.props.JsonData.PRINTS !== undefined &&
                                <ButtonGroup>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="light" className="me-1" size="sm">
                                                <Trans>Print</Trans>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </ButtonGroup>
                            }
                            <ButtonGroup>
                                <Button variant="outline-dark" size="sm" onClick={() => this.props.handleUpdateTable("REFRESH")}>
                                    <FontAwesomeIcon icon={faRefresh} />
                                </Button>
                            </ButtonGroup>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="btn-toolbar float-end" role="toolbar">

                        </div>
                    </div>
                </div>
            </>
        );
    }  
}

class FormHeader extends Component {
    //static displayName = FormHeader.name;

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                {
                    this.props.JsonData.SETTINGS.Header.Tabs !== undefined && 
                    <FormTabs formid={this.props.formid} id={this.props.id} JsonData={this.props.JsonData} JsonParam={this.props.JsonParam} handleUpdateTable={this.props.handleUpdateTable} fieldChange={this.props.fieldChange} getFields={this.props.getFields} />
                }                    
            </>
        );
    }    
}

class FormTabs extends React.Component {
    //static displayName = FormTabs.name;

    constructor(props) {
        super(props);        
    }    


    render() {
        const HeaderTabs = this.props.JsonData.SETTINGS.Header.Tabs;   
        var TabsClassName = "";

        if (this.props.JsonData.SETTINGS.Header.Border === undefined || this.props.JsonData.SETTINGS.Header.Border) {
            TabsClassName = "border-bottom border-dark";
        }

      return (
            <>
                <div id="Tabs">
                    <Tabs defaultActiveKey={HeaderTabs[0].TabName} className="mb-3 nav-underline">                
                    {
                          HeaderTabs.map(HeaderTab => {
                              return (
                                <Tab className={TabsClassName} eventKey={HeaderTab.TabName} title={i18n.t(HeaderTab.TabName)}>
                                      <FormTab key={HeaderTab.TabName} formid={this.props.formid} id={this.props.id} TabName={HeaderTab.TabName} HeaderTab={HeaderTab} JsonData={this.props.JsonData} JsonParam={this.props.JsonParam} handleUpdateTable={this.props.handleUpdateTable} fieldChange={this.props.fieldChange} getFields={this.props.getFields} />
                                </Tab>                                    
                            )
                            }                            
                        )   
                    }
                    </Tabs>
                </div>
            </>
        );
    }
}

class FormTab extends Component {
    //static displayName = FormTab.name;

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                {
                    this.props.HeaderTab.TabRows !== undefined &&
                    this.props.HeaderTab.TabRows.map((row, index) =>
                        <Row key={index} className="mb-3 g-3">
                            {
                                row.FIELDS !== undefined &&
                                row.FIELDS.map(field =>
                                    <FormField key={field.id} Field={field} JsonData={this.props.JsonData} JsonParam={this.props.JsonParam} handleUpdateTable={this.props.handleUpdateTable} fieldChange={this.props.fieldChange} getFields={this.props.getFields} />
                                )
                            }
                        </Row>
                    )
                }
                {
                    this.props.HeaderTab.TableName !== undefined &&
                    <TableData formid={this.props.HeaderTab.TableName} id={this.props.id} />
                }
            </>
        );
    }
}

class FormModal extends Component {
    //static displayName = FormModalr.name;

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                {
                    this.props.JsonData.SETTINGS.Form.Rows !== undefined &&
                    this.props.JsonData.SETTINGS.Form.Rows.map((row, index) =>
                        <Row key={index} className="mb-3 g-3">
                            {
                                row.FIELDS !== undefined &&
                                row.FIELDS.map(field =>
                                    <FormField key={field.id} Field={field} JsonData={this.props.JsonData} JsonParam={this.props.JsonParam} handleUpdateTable={this.props.handleUpdateTable} fieldChange={this.props.fieldChange} getFields={this.props.getFields} />
                                )
                            }
                        </Row>
                    )
                }
            </>
        );
    }
}

class FormActionbar extends Component {
    //static displayName = FormToolbar.name;

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <div className="row">
                    <ButtonToolbar className="justify-content-end">
                        <ButtonGroup>
                            <Button variant="secondary" onClick={this.props.closeModal}>{i18n.t("Close")}</Button>
                            {
                                this.props.JsonData.ACTIONS !== undefined &&
                                this.props.JsonData.ACTIONS.map(action =>
                                    //<Button variant="primary" key={action.action}>{i18n.t(action.action)}</Button>
                                    <ActionItem type="BUTTON" key={action.action} action={action} formid={this.props.formid} handleUpdateTable={this.props.handleUpdateTable} getFields={this.props.getFields} />
                                )
                            }
                        </ButtonGroup>
                    </ButtonToolbar>
                </div>                
            </>
        );
    }
}

class FormField extends Component {
    //static displayName = FormField.name;

    constructor(props) {
        super(props);
    }

    change = (newValue, actionMeta) => {
     this.props.fieldChange(this.props.Field.id, newValue.value)
    }

    multiChange = (newValue, actionMeta) => {
        this.props.fieldChange(this.props.Field.id, newValue)
    }

    render() {
        const FieldSize = "col-md-" + this.props.Field.size;
        const FieldDisabled = this.props.Field.readOnly;
        //const FieldValue = this.props.JsonData.DATA[0][this.props.Field.id];
        let FieldValue = "";
        if (this.props.JsonData !== undefined) {
            FieldValue = this.props.JsonData.DATA[0][this.props.Field.id];
        }

        let contents = <></>;

        if (this.props.Field.input === undefined) {
            if (this.props.Field.type === "STRING") {
                contents =
                    <>
                    <Form.Group as={Col}>
                        <Form.Label><Trans>{this.props.Field.id}</Trans></Form.Label>
                        <Form.Control defaultValue={FieldValue} disabled={FieldDisabled} onChange={(event) => this.props.fieldChange(this.props.Field.id, event.target.value)}/>
                    </Form.Group>
                    </>;
            }
            else
                if (this.props.Field.type === "NUMBER") {
                contents =
                    <>
                        <Form.Group as={Col}>
                            <Form.Label><Trans>{this.props.Field.id}</Trans></Form.Label>
                            <Form.Control defaultValue={FieldValue} disabled={FieldDisabled} onChange={(event) => this.props.fieldChange(this.props.Field.id, event.target.value)} />
                        </Form.Group>
                    </>;
                }
                else
                    if (this.props.Field.type === "DATE") {  
                        var FieldDateValue = "";
                        try {
                            FieldDateValue = FieldValue.split("T")[0];
                        } catch {
                 
                        }

                        contents =
                            <>                        
                                <Form.Group as={Col}>
                                    <Form.Label><Trans>{this.props.Field.id}</Trans></Form.Label>
                                    <Form.Control defaultValue={FieldDateValue} type="date" disabled={FieldDisabled} onChange={(event) => this.props.fieldChange(this.props.Field.id, event.target.value)} />
                                </Form.Group>
                            </>;
                    }
                    else
                        if (this.props.Field.type === "PASSWORD") {
                            contents =
                                <>
                                    <Form.Group as={Col}>
                                        <Form.Label><Trans>{this.props.Field.id}</Trans></Form.Label>
                                        <Form.Control type="password" defaultValue={FieldValue} disabled={FieldDisabled} onChange={(event) => this.props.fieldChange(this.props.Field.id, event.target.value)} />
                                    </Form.Group>
                                </>;
                        }
                        else
                            if (this.props.Field.type === "MEMO") {
                                contents =
                                    <>
                                        <Form.Group as={Col}>
                                            <Form.Label><Trans>{this.props.Field.id}</Trans></Form.Label>
                                            <Form.Control as="textarea" rows={this.props.Field.row} defaultValue={FieldValue} disabled={FieldDisabled} onChange={(event) => this.props.fieldChange(this.props.Field.id, event.target.value)} />
                                        </Form.Group>
                                    </>;
                            }
                            else
                                contents =
                                    <>
                                        <p>{this.props.Field.type}</p>
                                    </>;
        }
        else
            if (this.props.Field.input === "Select") {
                const SelectOptions = this.props.JsonData.SELECT[0][this.props.Field.id];
                let FieldValueLabel = "";

                if (SelectOptions !== undefined) {
                    for (var i in SelectOptions) {
                        if (FieldValue === SelectOptions[i].value) {
                            FieldValueLabel = SelectOptions[i].label;
                            break;
                        }
                    }
                }

                contents =
                    <>
                        {
                        SelectOptions !== undefined &&
                        <Form.Group as={Col}>
                            <Form.Label><Trans>{this.props.Field.id}</Trans></Form.Label>
                            <ReactSelect
                                    options={SelectOptions}
                                    isDisabled={FieldDisabled}
                                    isSearchable={false}
                                    defaultValue={{ value: FieldValue, label: FieldValueLabel }}
                                    onChange={this.change}
                            />
                        </Form.Group>
                    }                    
                    </>;
            }
            else
                if (this.props.Field.input === "MultiSelect") {
                    const SelectOptions = this.props.JsonData.SELECT[0][this.props.Field.id];
                    let FieldValueData = [];

                    if (SelectOptions !== undefined && FieldValue !== undefined) {
                        for (var i in SelectOptions) {
                            for (var j in FieldValue.split(",")) {
                                if (FieldValue.split(",")[j] === SelectOptions[i].value) {
                                    FieldValueData.push(SelectOptions[i]);
                                }
                            }
                        }

                        this.props.fieldChange(this.props.Field.id, FieldValueData)
                    }

                    contents =
                        <>
                            {
                                SelectOptions !== undefined &&
                                <Form.Group as={Col}>
                                    <Form.Label><Trans>{this.props.Field.id}</Trans></Form.Label>
                                    <ReactMultiSelect
                                        options={SelectOptions}
                                        isDisabled={FieldDisabled}
                                        isSearchable={false}
                                        defaultValue={FieldValueData}
                                        onChange={this.multiChange}
                                        isMulti
                                    />
                                </Form.Group>
                            }
                        </>;
                }
            else
                if (this.props.Field.input === "SelectSearch") {
                    //const Select = this.props.JsonData.SELECT[0][this.props.Field.id];
                    contents =
                        <>
                            <SearchFormField Field={this.props.Field} FieldValue={FieldValue} FieldDisabled={FieldDisabled} change={this.change} getFields={this.props.getFields} />                            
                        </>;
                }

        return (
            <>
                <div className={FieldSize} >
                    {contents}
                </div>

            </>
        );
    }
}

class SearchFormField extends Component {
    //static displayName = SearchFormField.name;

    constructor(props) {
        super(props);
        this.state = {}
    }

    loadOptions = (inputValue, callback) => {
        if (inputValue !== "") {
            var fields = this.props.getFields();
            this.populateData(inputValue, callback, fields);
        }
        else {
            callback([]);
        }        
    };

    render() {
        return (
            <>
                <Form.Group as={Col}>
                    <Form.Label><Trans>{this.props.Field.id}</Trans></Form.Label>
                    {
                        this.props.FieldDisabled
                        ?
                        <Form.Control value={this.props.FieldValue} disabled={this.props.FieldDisabled} />
                        :
                        <ReactAsyncSelect
                            loadOptions={this.loadOptions}
                            isDisabled={this.props.FieldDisabled}
                            defaultValue={{ value: this.props.FieldValue, label: this.props.FieldValue }}
                            defaultOptions
                            cacheOptions
                            onChange={this.props.change}
                        />
                    }
                    
                </Form.Group>                
            </>
        );
    }

    async populateData(inputValue, callback, fields) {
        //const { i18n } = useTranslation();
        const cookies = new Cookies();
        var dbName = cookies.get("connectionDb");
        var language = cookies.get("language");

        const token =  await authService.getAccessToken();
        const response = await fetch('api/forms/FormSearch', {
            method: "POST",
            headers: !token
                ? {}
                : {
                    'Authorization': `Bearer ${token}`
                },
            body: JSON.stringify({ fieldid: this.props.Field.id, value: inputValue, fields: fields, language: language, connection: dbName })
        });

        if (response.status === 200) {            
            /*var options = [];
            options.push({ value: 'Massachusetts Institute of Technology', label: 'Massachusetts Institute of Technology' });
            options.push({ value: 'University of Chicago', label: 'University of Chicago' });*/        

            try {
                const data = await response.json();
                var data2 = JSON.parse(data.data);
                callback(data2);
            } catch {
                callback([]);
            }
        }
        else {
            callback([]);
        }
    }
}

class ActionItem extends Component {
    //static displayName = ActionItem.name;

    constructor(props) {
        super(props);
        this.state = { loading: false, modalShow: false, error: false, errorMessage: "" };
        this.fields = {};
    }

    ActionItemClick = () => {
        if (this.props.action.type === "ACTION") {
            this.setState({ loading: true });
            this.populateAction();
        } else
            if (this.props.action.type === "SAVE") {
                var fields = this.props.getFields();
                this.setState({ loading: true });
                this.populateSave(fields);
            } else
                if (this.props.action.type === "FORM") {
                    //this.setState({ loading: true });
                    alert('form');
                    //this.setState({ loading: false });
                } else
                    if (this.props.action.type === "URL") {
                        this.setState({ loading: true });
                        this.populateAction(this.props.action.url);
                    }
    }

    setError = (errorMessage) => {
        if (this.props.action.confirm) {
            this.setState({ loading: false, error: true, errorMessage: errorMessage })
        } else {
            alert(errorMessage);
            this.setState({ loading: false });
        }        
    }

    fieldChange = (field, value) => {
        this.fields[field] = value;
    }

    getFields = () => {
        return this.fields;
    }

    async populateAction(url) {
        const cookies = new Cookies();
        var dbName = cookies.get("connectionDb");
        var language = cookies.get("language");

        if (url === undefined) {
            url = 'api/forms/FormActions';
        }

        const token = await authService.getAccessToken();
        const response = await fetch(url, {
            method: "POST",
            headers: !token
                ? {}
                : {
                    'Authorization': `Bearer ${token}`
                },
            body: JSON.stringify({ action: this.props.action.action, id: this.props.action.id, language: language, connection: dbName, fields: this.fields })
        });

        if (response.status === 200) {
            //this.props.handleUpdateTable("REFRESH", {});
            try {
                const data = await response.json();
                if (data !== undefined && data.data !== undefined && JSON.parse(data.data).reload !== undefined) {
                    window.location.href = JSON.parse(data.data).reload;
                }
                else {
                    this.props.handleUpdateTable("REFRESH", {});
                }
            } catch {
                this.props.handleUpdateTable("REFRESH", {});
            }
        }
        else {
            try {
                const data = await response.json();
                this.setError(data.error);
                //this.setState({ error: true, errorMessage: data.error })
            } catch {
                this.setError(response.status + " " + response.statusText);
                //this.setState({ error: true, errorMessage: response.status + " " + response.statusText })
            }
        }
    }

    async populateSave(fields) {
        const cookies = new Cookies();
        var dbName = cookies.get("connectionDb");
        var language = cookies.get("language");

        const token = await authService.getAccessToken();
        const response = await fetch('api/forms/FormSave', {
            method: "POST",
            headers: !token
                ? {}
                : {
                    'Authorization': `Bearer ${token}`
                },
            body: JSON.stringify({ formName: this.props.formid, fields: fields, language: language, connection: dbName })
        });

        if (response.status === 200) {
            this.props.handleUpdateTable("REFRESH", {});
        }
        else {
            try {
                const data = await response.json();
                this.setError(data.error);
                //this.setState({ error: true, errorMessage: data.error })
            } catch {
                this.setError(response.status + " " + response.statusText);
                //this.setState({ error: true, errorMessage: response.status + " " + response.statusText })
            }
        }
    }

    render() {
        var content = <></>;        

        if (this.props.type === "BUTTON") {
            if (this.props.action.confirm) {
                content = <Button href={this.props.action.href} onClick={() => this.setState({ modalShow: true, error: false })}>{i18n.t(this.props.action.action)}</Button>
            } else
                if (this.props.action.type === "FORM") {
                    content = <ActionItemForm action={this.props.action} handleUpdateTable={this.props.handleUpdateTable} />
                } else {
                        content = <Button href={this.props.action.href} onClick={() => this.ActionItemClick()} disabled={this.state.loading}>{i18n.t(this.props.action.action)}</Button>
                    }
        }
        else {
            if (this.props.action.confirm) {
                content = <Dropdown.Item href={this.props.action.href} onClick={() => this.setState({ modalShow: true, error: false })}>{i18n.t(this.props.action.action)}</Dropdown.Item>
            }
            else
                if (this.props.action.type === "DIV") {
                    content = <Dropdown.Divider />
                } else
                    if (this.props.action.type === "FORM") {
                        content = <ActionItemForm action={this.props.action} handleUpdateTable={this.props.handleUpdateTable} />
                    } else {
                            content = <Dropdown.Item href={this.props.action.href} onClick={() => this.ActionItemClick()}>{i18n.t(this.props.action.action)}</Dropdown.Item>
                        }
        }

        return (
            <>
                {content}       

                {
                    this.props.action.confirm &&
                    <Modal
                        show={this.state.modalShow}
                        onHide={() => this.setState({ modalShow: false })}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">{i18n.t(this.props.action.action)}?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>
                                {this.props.action.id !== undefined && this.props.action.id}
                                {this.state.error && <ErrorAlert message={this.state.errorMessage} />}
                            </p>
                            {
                                this.state.loading &&
                                <div className="d-flex justify-content-center">
                                    <Spinner className="p-0" animation="border" size="sm" />  
                                </div>                                                              
                            }
                            { 
                                this.props.action.fields !== undefined &&
                                this.props.action.fields.map(field =>
                                    <FormField key={field.id} Field={field} fieldChange={this.fieldChange} getFields={this.getFields} />
                                )
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <ButtonToolbar className="justify-content-end">
                                <ButtonGroup>
                                    <Button variant="secondary" onClick={() => this.setState({ modalShow: false })}>{i18n.t("NO")}</Button>
                                    <Button 
                                        variant="primary" 
                                        onClick={() => this.ActionItemClick()}
                                        disabled={this.state.loading}
                                    >                 
                                        {i18n.t("YES")}
                                    </Button>
                                </ButtonGroup>
                            </ButtonToolbar>
                        </Modal.Footer>
                    </Modal>
                }                
            </>
        );
    }
}

class ActionItemForm extends Component {
    //static displayName = TableFieldForm.name;

    constructor(props) {
        super(props);
        this.state = { modalShow: false, error: false, errorMessage: "" };
    }

    closeModal = () => {
        this.setState({ modalShow: false });
    };

    render() {
        return (
            <>
                <Dropdown.Item href={this.props.action.href} onClick={() => this.setState({ modalShow: true, error: false })}>{i18n.t(this.props.action.action)}</Dropdown.Item>

                <Modal
                    show={this.state.modalShow}
                    onHide={() => this.setState({ modalShow: false })}
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter"><Trans>{this.props.action.formName}</Trans>&nbsp;{this.props.action.id}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormData formid={this.props.action.formName} id={this.props.action.id} handleUpdateTable={this.props.handleUpdateTable} closeModal={this.closeModal} />
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}