import Spinner from 'react-bootstrap/Spinner';
export function Loading() {
    return (
        <div className="d-flex justify-content-center pt-5 pb-5">
            <Spinner animation="border" variant="info" />
        </div>      
  );
};

export function LoadingSmall() {
    return (
        <div>
            <Spinner animation="border" variant="info" className="spinner-border-sm" />
        </div>
    );
};