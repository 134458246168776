import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import FeatherIcon from 'feather-icons-react';
import Badge from '../base/Badge.tsx';
import { Trans } from 'react-i18next';

export function ErrorAlertLarge({ message }) {
    return (
        <Alert key="danger" variant="outline-danger">
            <FeatherIcon icon='alert-circle' size="20" />
            &nbsp;
            <Trans>{message}</Trans>
        </Alert>
    );
};

export function ErrorAlert({ message }) {
    return (
        <>
            <div className="row">
                <Badge
                    bg="danger"
                    variant="phoenix"
                    iconPosition="start"
                    className="fs-10"
                    icon={<FeatherIcon icon="alert-circle" size={12} className="ms-1" />}
                >
                    &nbsp;
                    <Trans>{message}</Trans>
                </Badge>
            </div>
        </>        
    );
};

export function ErrorAlertClose({ message }) {
    const [setShow] = useState(true);

    return (
        <Alert key="danger" variant="outline-danger" onClose={() => setShow(false)} dismissible>
            <FeatherIcon icon='alert-circle' size="20" />
            &nbsp;
            <Trans>{message}</Trans>
        </Alert>
    );
};

export function WarningAlertClose({ message }) {
    const [setShow] = useState(true);

    return (
        <Alert key="warning" variant="outline-warning" onClose={() => setShow(false)} dismissible>
            <FeatherIcon icon='alert-triangle' size="20" />
            &nbsp;
            <Trans>{message}</Trans>
        </Alert>
    );
};

export function SuccessAlertClose({ message }) {
    const [show, setShow] = useState(true);

    useEffect(() => {
        const timeId = setTimeout(() => {
            // After 5 seconds set the show value to false
            setShow(false)
        }, 5000)

        return () => {
            clearTimeout(timeId)
        }
    }, []);

    // If show is false the component will return null and stop here
    if (!show) {
        return null;
    }

    // If show is true this will be returned
    return (
        <Alert key="success" variant="outline-success" onClose={() => setShow(false)}>
            <FeatherIcon icon='check' size="20" />
            &nbsp;
            <Trans>{message}</Trans>
        </Alert>
    )
};