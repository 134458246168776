import React, { Component } from 'react';
import { NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { LoadingSmall } from './shared/loadings';
import { ErrorAlert } from './shared/alerts';
import authService from './api-authorization/AuthorizeService'

export class ApplicationMenu extends Component {
    static displayName = ApplicationMenu.name;

    constructor(props) {
        super(props);
        this.state = { forecasts: [], loading: true, error: false, errorMessage: "", isAuthenticated: false, userName: null };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateData());
        this.populateData();
    }   

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    static renderForecasts(forecasts) {
        return (
            <>
                {forecasts.data !== undefined && forecasts.data.map(forecast =>
                    forecast.parentId === 0 &&
                    <AppMainItem key={forecast.id} data={forecasts.data} id={forecast.id} menuName={forecast.menuName} menuType={forecast.menuType} menuHref={forecast.menuHref} parentId={forecast.parentId} orderId={forecast.orderId} />
                    
                )}
            </>
        );
    }

    render() {
        let contents = <></>

        if (this.state.loading) {
            contents = <LoadingSmall />
        }
        else {
            if (this.state.isAuthenticated) {
                contents = ApplicationMenu.renderForecasts(this.state.forecasts);
            }
        }

        return (
            <>
                {this.state.error && <ErrorAlert message={this.state.errorMessage} />}
                {contents}
            </>
        );
    }

    async populateData() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])

        if (!isAuthenticated) {
            this.setState({ loading: false, isAuthenticated: isAuthenticated, userName: user && user.name });
        }
        else {
            const token = await authService.getAccessToken();
            const response = await fetch('api/general/MainMenuInfo', {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });

            if (response.status === 200) {
                const data = await response.json();
                this.setState({ forecasts: data, loading: false, isAuthenticated: isAuthenticated, userName: user && user.name });
            }
            else {
                this.setState({ loading: false, error: true, errorMessage: response.status + " " + response.statusText, isAuthenticated: isAuthenticated, userName: user && user.name });
            }
        }        
    }
}

function AppMainItem({ data, id, menuName, menuType, menuHref, parentId, orderId }) {
    return (
        <>
            {menuType === "MM" &&
                <AppMenuMM data={data} id={id} menuName={menuName} menuType={menuType} menuHref={menuHref} parentId={parentId} orderId={orderId} />
            }
            {menuType === "M" &&
                <AppMenuM data={data} id={id} menuName={menuName} menuType={menuType} menuHref={menuHref} parentId={parentId} orderId={orderId} />
            }
            {menuType === "H" &&
                <AppMenuItem id={id} menuName={menuName} menuType={menuType} menuHref={menuHref} parentId={parentId} orderId={orderId} />
            }
        </>
    )
}

function AppMenuMM({ data, id, menuName, menuType, menuHref, parentId, orderId }) {
    return (
        <>
            <UncontrolledDropdown nav inNavbar className="text-dark">
                <DropdownToggle nav caret>
                    <Trans>{menuName}</Trans>
                </DropdownToggle>
                <DropdownMenu className="navbar-dropdown-caret dropdown-menu-card py-0 dropdown-menu" style={{ minWidth: "60vh" }}>
                    <div className="border-0 scrollbar" style={{ maxHeight: "60vh" }} >
                        <div className="px-3 pt-4 pb-3 img-dropdown scrollbar">
                            <div className="gx-4 gy-5 row">
                                <div className="col-md-4 col-sm-6 col-12">
                                    {data.map(item =>
                                        item.parentId === id && item.menuType === 'MG' && item.orderId < 1000 &&
                                        <AppMenuMMGroup key={item.id} data={data} id={item.id} menuName={item.menuName} menuType={item.menuType} menuHref={item.menuHref} parentId={item.parentId} orderId={item.orderId} />
                                    )}
                                </div>
                                <div className="col-md-4 col-sm-6 col-12">
                                    {data.map(item =>
                                        item.parentId === id && item.menuType === 'MG' && item.orderId >= 1000 && item.orderId < 2000 &&
                                        <AppMenuMMGroup key={item.id} data={data} id={item.id} menuName={item.menuName} menuType={item.menuType} menuHref={item.menuHref} parentId={item.parentId} orderId={item.orderId} />
                                    )}
                                </div>
                                <div className="col-md-4 col-sm-6 col-12">
                                    {data.map(item =>
                                        item.parentId === id && item.menuType === 'MG' && item.orderId >= 2000 &&
                                        <AppMenuMMGroup key={item.id} data={data} id={item.id} menuName={item.menuName} menuType={item.menuType} menuHref={item.menuHref} parentId={item.parentId} orderId={item.orderId} />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </DropdownMenu>
            </UncontrolledDropdown>
        </>
    )
}

function AppMenuMMGroup({ data, id, menuName, menuType, menuHref, parentId, orderId }) {
    return (
        <>
            <div className="dropdown-item-group mt-3">
                <h6 className="dropdown-item-title"><Trans>{menuName}</Trans></h6>
                {data.map(item =>
                    item.parentId === id && item.menuType === 'H' &&
                    <AppMenuMItem key={item.id} id={item.id} menuName={item.menuName} menuHref={item.menuHref} />
                )}                
            </div>
        </>
    )
}

function AppMenuM({ data, id, menuName, menuType, menuHref, parentId, orderId }) {
    return (
        <>
            <UncontrolledDropdown nav inNavbar className="text-dark">
                <DropdownToggle nav caret>
                    <Trans>{menuName}</Trans>
                </DropdownToggle>
                <DropdownMenu className="navbar-dropdown-caret dropdown-menu">
                    {data.map(item =>
                        item.parentId === id && item.menuType === 'H' &&
                        <AppMenuMItem key={item.id} id={item.id} menuName={item.menuName} menuHref={item.menuHref}/>
                    )}
                </DropdownMenu>
            </UncontrolledDropdown>
        </>
    )
}

function AppMenuMItem({ id, menuName, menuHref }) {
    return (
        <>
            <DropdownItem tag={Link} to={menuHref} ><Trans>{menuName}</Trans></DropdownItem>
        </>
    )
}

function AppMenuItem({ id, menuName, menuHref }) {
    return (
        <>
            <NavItem>
                <NavLink tag={Link} className="text-dark" to={menuHref} ><Trans>{menuName}</Trans></NavLink>
            </NavItem>
        </>
    )
}