import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import authService from './AuthorizeService';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import { NavItem, Button, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Col } from 'reactstrap';
import FeatherIcon from 'feather-icons-react';
import { About } from '../About';
import { LanguageSwitcher } from '../languageSwitcher';
import { DatabaseSwitcher } from '../databaseSwitcher';
import { Trans } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import InputGroup from 'react-bootstrap/InputGroup';
import { ErrorAlert } from '../shared/alerts';



export class LoginMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      userName: null
    };
  }

  componentDidMount() {
    this._subscription = authService.subscribe(() => this.populateState());
    this.populateState();
  }

  componentWillUnmount() {
    authService.unsubscribe(this._subscription);
  }

  async populateState() {
    const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
    this.setState({
      isAuthenticated,
      userName: user && user.name
    });
  }

  render() {
    const { isAuthenticated, userName } = this.state;
    if (!isAuthenticated) {
      const registerPath = `${ApplicationPaths.Register}`;
      const loginPath = `${ApplicationPaths.Login}`;
      return this.anonymousView(registerPath, loginPath);
    } else {
      const profilePath = `${ApplicationPaths.Profile}`;
      const logoutPath = `${ApplicationPaths.LogOut}`;
      const logoutState = { local: true };
      return this.authenticatedView(userName, profilePath, logoutPath, logoutState);
    }
  }

  authenticatedView(userName, profilePath, logoutPath, logoutState) {
    return (<Fragment>
        <DatabaseSwitcher/>
        <UncontrolledDropdown nav inNavbar className="text-dark">
            <DropdownToggle nav caret>
                <span>
                    <FeatherIcon icon={"user"} size="16" /> {userName}
                </span>
            </DropdownToggle>
            <DropdownMenu className="navbar-dropdown-caret dropdown-menu">
                <DropdownItem href={profilePath}>
                    <span>
                        <FeatherIcon icon={"user"} size="16" /> <Trans>Manage</Trans>
                    </span>
                </DropdownItem>
                <NavLink className="dropdown-item text-dark" tag={Link} to={logoutPath} state={logoutState}>
                    <span>
                        &nbsp;&nbsp;<FeatherIcon icon={"log-out"} size="16" /> <Trans>Logout</Trans>
                    </span>
                </NavLink>
            </DropdownMenu>
        </UncontrolledDropdown>
        <LanguageSwitcher />
        <About />
    </Fragment>);
  }

  anonymousView(registerPath, loginPath) {
      return (<Fragment>
          {
              //<NavMenuItem icon={"log-in"} label={"Login"} route={loginPath} />
          }
          {
              <LoginMenuItem icon={"log-in"} label={"Login"} route={loginPath} />
          }          
          <LanguageSwitcher />
          <About />
      </Fragment>);
  }
}

class LoginMenuItem extends Component {
    constructor(props) {
        super(props);
        this.state = {show: false};
    }

    handleShow = () => {
        this.setState({ show: true });
    }

    handleClose = () => {
        this.setState({ show: false });
    }

    handleLogin = () => {
        this.setState({ show: false });
    }

    render() {
        return (
            <>
                <NavItem>
                    <NavLink className="text-dark" onClick={this.handleShow} href="#">
                        <span className="nav-link-icon">
                            <FeatherIcon icon="log-in" size="16" />
                            &nbsp;
                            <Trans>Login</Trans>
                        </span>
                    </NavLink>
                </NavItem>

                <Modal
                    show={this.state.show}
                    onHide={this.handleClose}
                    //size="lg"
                    centered
                    className="modal show"
                    //style={{ backgroundColor: "#000" }}
                >
                    <Modal.Header closeButton className="d-flex justify-content-center">
                        <Modal.Title id="contained-modal-title-vcenter"><Trans>Login</Trans></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <LoginForm route={this.props.route} />
                    </Modal.Body>                    
                </Modal>
            </>
        )        
    }
}

class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: false, error: false, showPassword : false};
    }

    setUserName = (value) => {
        this.setState({ username: value });
    }

    setPassword = (value) => {
        this.setState({ password: value });
    }

    formSubmit = () => {
        this.setState({ loading: true });        
        this.populateData();
        /*setTimeout(() => {
            this.populateData();
        }, 5000);*/
    }

    handleUserNameKeyDown = (e) => {
        if (e.key === 'Enter') {
            if (this.state.username === "" || this.state.username === undefined) {
                this.setState({ error: true, errorMessage: "Login is empty" });
            }
            else {
                this.inputPasword.focus();
                this.setState({ error: false });
            }
        }
    }

    handlePasswordKeyDown = (e) => {
        if (e.key === 'Enter') {
            if (this.state.password === "" || this.state.password === undefined) {
                this.setState({ error: true, errorMessage: "Password is empty" });
            }
            else {
                this.formSubmit();
            }
        }
    }

    render() {
        return (
            <>
                <Form>
                    {this.state.error && <ErrorAlert message={this.state.errorMessage} />}

                    {
                        this.state.loading &&
                        <div className="d-flex justify-content-center">
                            <Spinner className="p-0" animation="border" size="sm" />
                        </div>
                    }

                    <Form.Group as={Col}>
                        <Form.Label><Trans>User</Trans></Form.Label>
                        <InputGroup className="mb-3">
                            <InputGroup.Text><FeatherIcon icon="user" size="16"/></InputGroup.Text>
                            <Form.Control onChange={(event) => this.setUserName(event.target.value)} onKeyDown={this.handleUserNameKeyDown} autoFocus />
                        </InputGroup>

                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label><Trans>Password</Trans></Form.Label>
                        <InputGroup className="mb-3">
                            <InputGroup.Text><FeatherIcon icon="key" size="16"/></InputGroup.Text>
                            <Form.Control
                                type={this.state.showPassword ? "text" : "password"}
                                ref={(input) => { this.inputPasword = input; }} 
                                onChange={(event) => this.setPassword(event.target.value)} onKeyDown={this.handlePasswordKeyDown} />
                            <Button variant="phoenix-primary" onClick={() => this.setState({ showPassword: !this.state.showPassword })}>
                                <FeatherIcon icon={this.state.showPassword ? "eye-off" : "eye"} size="16"/>
                            </Button>
                        </InputGroup>
                    </Form.Group>

                    <div className="d-flex justify-content-center">
                        <Button
                            ref={(button) => { this.buttonSubmit = button; }}
                            className="btn-primary"
                            onClick={this.formSubmit}
                            disabled={this.state.loading}
                        >
                            <Trans>Login</Trans>                            
                        </Button>
                    </div>
                </Form>                
            </>
        )
    }

    async populateData() {
        const response = await fetch('api/account/Login', {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    'username': this.state.username,
                    'password': this.state.password
                }
            )
        });

        if (response.status === 200) {
            window.location.replace(this.props.route);            
        }
        else {
            try {
                const data = await response.json();
                this.setState({ loading: false, error: true, errorMessage: data.error });
            } catch {
                this.setState({ loading: false, error: true, errorMessage: response.status + " " + response.statusText });
            }
        }
    }
}