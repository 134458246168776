import { useTranslation } from 'react-i18next';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import FeatherIcon from 'feather-icons-react';
import Cookies from 'universal-cookie';

const lngs = {
    en: { nativeName: 'en' },
    ru: { nativeName: 'ru' }
};


export function LanguageSwitcher() {
    const { i18n } = useTranslation();

    function changeLanguage(lang){
        i18n.changeLanguage(lang)
        const cookies = new Cookies();
        cookies.set("language", lang, { path: "/" });
        window.location.reload(false)
    }

    return (
        <>
            <UncontrolledDropdown nav inNavbar className="text-dark">
                <DropdownToggle nav caret>
                    <span>
                        <FeatherIcon icon={"globe"} size="16" /> {i18n.language}
                    </span>
                </DropdownToggle>
                <DropdownMenu className="navbar-dropdown-caret dropdown-menu">
                    {Object.keys(lngs).map((lng) => (
                        <DropdownItem key={lng} type="submit" onClick={() => changeLanguage(lng)}>
                            <span>
                                {lngs[lng].nativeName}
                            </span>
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </UncontrolledDropdown>
        </>
    );
}