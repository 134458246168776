import React, { Component } from 'react';
//import Button from 'react-bootstrap/Button';
import Carousel from 'react-bootstrap/Carousel';
import Card from 'react-bootstrap/Card';
import { Trans } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons'

export class Home extends Component {
  static displayName = Home.name;

  componentDidMount() {
    document.title = "PowerWMS"
  }

  render() {
    return (
      <div>
            <div className="row d-flex justify-content-center">
                <Carousel style={{ width: "85vh" }}
                    className='theme-slider'
                    indicators={false}
                    nextIcon={
                        <FontAwesomeIcon icon={faAngleRight} />
                    }
                    prevIcon={
                        <FontAwesomeIcon icon={faAngleLeft} />
                    }>
                    <Carousel.Item>
                        <img className="d-block w-100" src="/images/warehouse-logo.png" />
                    </Carousel.Item>
                </Carousel>
            </div>
            <div className="row d-flex justify-content-center py-5">
                <Card style={{ width: '20rem' }} className='overflow-hidden ms-3'>
                    <Card.Img src="/images/unloading.jpg" variant='top' />
                    <Card.Body>
                        <Card.Title as='h5' className="d-flex justify-content-center">
                            <Trans>Unloading</Trans>
                        </Card.Title>
                    </Card.Body>
                </Card>
                <Card style={{ width: '20rem' }} className='overflow-hidden ms-3'>
                    <Card.Img src="/images/receipt.jpg" variant='top' />
                    <Card.Body>
                        <Card.Title as='h5' className="d-flex justify-content-center">
                            <Trans>ASN</Trans>
                        </Card.Title>
                    </Card.Body>
                </Card>
                <Card style={{ width: '20rem' }} className='overflow-hidden ms-3'>
                    <Card.Img src="/images/placement.jpg" variant='top' />
                    <Card.Body>
                        <Card.Title as='h5' className="d-flex justify-content-center">
                            <Trans>Move</Trans>
                        </Card.Title>
                    </Card.Body>
                </Card>
                <Card style={{ width: '20rem' }} className='overflow-hidden ms-3'>
                    <Card.Img src="/images/picking.jpg" variant='top' />
                    <Card.Body>
                        <Card.Title as='h5' className="d-flex justify-content-center">
                            <Trans>Picking</Trans>
                        </Card.Title>
                    </Card.Body>
                </Card>
                <Card style={{ width: '20rem' }} className='overflow-hidden ms-3'>
                    <Card.Img src="/images/shipping.jpg" variant='top' />
                    <Card.Body>
                        <Card.Title as='h5' className="d-flex justify-content-center">
                            <Trans>Shipping</Trans>
                        </Card.Title>
                    </Card.Body>
                </Card>
            </div>
      </div>
    );
  }
}
