import React, { Component } from 'react';
import { NavMenu } from './NavMenu';
//import { withNamespaces } from 'react-i18next';

//Phoenix
import './css/phoenix/theme.min.css';
import './css/phoenix/user.min.css';

export class Layout extends Component {
    static displayName = Layout.name;    

  render() {
    return (
      <div className="px-0 container-fluid">
        <NavMenu />
        <div className="content" tag="main">
          {this.props.children}
        </div>
      </div>      
    );
  }
}
