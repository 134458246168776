import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { NavItem, NavLink } from 'reactstrap';
import FeatherIcon from 'feather-icons-react';
//import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { Loading } from './shared/loadings';
import { ErrorAlert } from './shared/alerts';
import authService from './api-authorization/AuthorizeService'
import Cookies from 'universal-cookie';
import preval from 'preval.macro';
import { format } from "date-fns";

export function About() {
    const [modalShow, setModalShow] = React.useState(false);

    return (
        <>
            <NavItem>
                <NavLink className="text-dark" href="#" onClick={() => setModalShow(true)}>
                    <span className="nav-link-icon">
                        <FeatherIcon icon={"help-circle"} size="16" />
                    </span>
                </NavLink>
            </NavItem>

            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="modal show"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter"><Trans>About</Trans></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AboutForm/>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setModalShow(false)}><Trans>Close</Trans></Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

class AboutForm extends Component {
    static displayName = AboutForm.name;

    constructor(props) {
        super(props);
        this.state = { forecasts: [], loading: true, error: false, errorMessage: "", userName: "" };
    }

    componentDidMount() {
        this.populateData();
    }

    static renderForecasts(forecasts, userName) {
        const cookies = new Cookies();
        const db = cookies.get("connectionDb");
        const dbName = cookies.get("connectionName");
        const packageJson = require('../../package.json')
        const buildDate = preval`module.exports = new Date()`

        return (
            <>
                <div className="row mb-3">
                    <label className="col-sm-3 col-form-label"><Trans>Author</Trans></label>
                    <label className="col-sm-9 col-form-label">&copy; {(new Date().getFullYear())} - <a className="col-sm-9 col-form-label" href="https://powerwms.ru" target="_blank" rel="noreferrer">PowerWMS</a> - Ivan Filatov</label>
                </div>
                <div className="row mb-3">
                    <label className="col-sm-3 col-form-label"><Trans>Version</Trans></label>
                    <label className="col-sm-9 col-form-label">FE: {packageJson.version} ({format(buildDate, 'dd.MM.yyyy HH:mm')}) BE: {forecasts.version} ({forecasts.buildDate})</label>
                </div>
                <div className="row mb-3">
                    <label className="col-sm-3 col-form-label"><Trans>UserName</Trans></label>
                    <label className="col-sm-9 col-form-label">{forecasts.userName}</label>
                </div>
                <div className="row mb-3">
                    <label className="col-sm-3 col-form-label"><Trans>Connection</Trans></label>
                    <label className="col-sm-9 col-form-label">{db} - {dbName}</label>
                </div>
                <div className="row mb-3">
                    <label className="col-sm-3 col-form-label"><Trans>LicenceId</Trans></label>
                    <label className="col-sm-9 col-form-label">{forecasts.licenceId}</label>
                </div>
                <div className="row mb-3">
                    <label className="col-sm-3 col-form-label"><Trans>LicenceDate</Trans></label>
                    <label className="col-sm-9 col-form-label">{forecasts.licenceDate.split('T')[0]}</label>
                </div>
                <div className="row mb-3">
                    <label className="col-sm-3 col-form-label"><Trans>ServerName</Trans></label>
                    <label className="col-sm-9 col-form-label">{forecasts.serverName}</label>
                </div>
                <div className="row mb-3">
                    <label className="col-sm-3 col-form-label"><Trans>Processor</Trans></label>
                    <label className="col-sm-9 col-form-label">{forecasts.processorID}</label>
                </div>
                <div className="row mb-3">
                    <label className="col-sm-3 col-form-label"><Trans>Directory</Trans></label>
                    <label className="col-sm-9 col-form-label">{forecasts.currentDirectory}</label>
                </div>
            </>
        );
    }

    render() {
        let contents = this.state.loading
            ? <Loading />
            : AboutForm.renderForecasts(this.state.forecasts, this.state.userName);

        return (
            <>                
                {this.state.error && <ErrorAlert message={this.state.errorMessage} />}  
                {contents}
            </>
        );
    }

    async populateData() {
        const [user] = await Promise.all([authService.getUser()])

        const token = await authService.getAccessToken();
        const response = await fetch('api/general/AboutInfo', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });

        if (response.status === 200) {
            const data = await response.json();
            this.setState({ forecasts: data, loading: false, userName: user && user.name });
        }
        else {
            this.setState({ loading: false, error: true, errorMessage: response.status + " " + response.statusText });
        }
    }
}